import helpers from "../helpers";
export default {
    SET_ORIGIN: 'SET_ORIGIN',
    SET_PAYMENT: 'SET_PAYMENT',
    SET_TOKEN: 'SET_TOKEN',
    SET_USER: 'SET_USER',
    AUTHENTICATED: 'AUTHENTICATED',
    GET_USER: 'GET_USER',
    ATTEMPT: 'ATTEMPT',
    CLOSE: 'CLOSE',
    UPDATE_CARD_NUMBER: 'UPDATE_CARD_NUMBER',
    UPDATE_MONTH: 'UPDATE_MONTH',
    UPDATE_YEAR: 'UPDATE_YEAR',
    UPDATE_CVV: 'UPDATE_CVV',
    UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
    UPDATE_COUNTRY: 'UPDATE_COUNTRY',
    PAY_ACTION: 'PAY_ACTION',
    INITIATE_CARD_ACTION: 'INITIATE_CARD_ACTION',
    GET_PAYMENT: 'GET_PAYMENT',
    SET_TRANSACTION: 'SET_TRANSACTION',
    GET_TRANSACTION: 'GET_TRANSACTION',
    MOMO_CURRENCIES: ['UGX', 'KES', 'RWF'],
    SET_FEES: 'SET_FEES',
    GET_COUNTRIES: 'GET_COUNTRIES',
    SET_COUNTRIES: 'SET_COUNTRIES',
    GET_FEES: 'GET_FEES',
    SET_FEES_TYPE: 'SET_FEES_TYPE',
    DEFAULT_FEES_TYPE: 'default',
    GET_BASE_PAYMENT: 'GET_BASE_PAYMENT',
    GET_DRIVER: 'GET_DRIVER',
    SET_DEFAULT_CARD_DRIVER: 'SET_DEFAULT_CARD_DRIVER',
    SET_DEFAULT_MOMO_DRIVER: 'SET_DEFAULT_MOMO_DRIVER',
    FLUTTERWAVE: 'flutterwave',
    AIRTEL_UG: 'airtel_ug',
    CELLULANT: 'cellulant',
    MTN: 'mtn',
    DLOCAL: 'dlocal',
    PAYPAL: 'paypal_card',
    POLL: ['airtel_ug', 'cellulant', 'mtn'],
    CELLULANT_COUNTRIES: [/*'UG', 'KE', 'TZ', 'GH', 'MW', 'ZA'*/],
    DLOCAL_PAYER_DOCUMENTS: {
        'AR': {label: 'DNI or CUIT', format: 'between 7 to 9, or 11 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredDigitsBetweenOrLength(value, format, 7, 10, 11);
            }},
        'BD': {label: 'NID Card or National Identity Card', format: '13 - 17 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 13, 18);
            }},
        'BO': {label: 'CI', format: 'between 5 to 20 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'BR': {label: 'CPF or CNPJ', format: 'between 11 to 14 digits full CPF validation', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 11, 15);
            }},
        'CM': {label: 'CNI or ID', format: '8 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 8)
            }},
        'CL': {label: 'CI/RUT', format: 'between 8 to 9 characters', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 8, 10);
            }},
        'CN': {label: '公民身份号码 (citizen ID number)', format: 'between 5 to 20 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'CO': {label: 'CC', format: 'between 6 to 10 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 6, 11);
            }},
        'CR': {label: 'CI', format: '9 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 9);
            }},
        'DO': {label: 'ID', format: '11 digits', required: false,
            validator: (value, format) => {
                return helpers.nonRequiredCharactersLength(value, format, 11);
            }},
        'EC': {label: 'CI', format: 'between 5 to 20 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'SV': {label: 'DUI', format: '9 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 9);
            }},
        'EG': {label: 'ID', format: '14 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 14);
            }},
        'GH': {label: 'Ghana Card', format: '16 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 16);
            }},
        'GT': {label: 'CUI', format: '13 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 13);
            }},
        'HN': {label: 'DNI', format: '13 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 13);
            }},
        'IN': {label: 'PAN', format: '10 characters (5 letters, 4 numbers, 1 letter) full PAN validation', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 10);
            }},
        'ID': {label: 'NIK', format: '16 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 16);
            }},
        'CI': {label: 'National Identity card (Carte nationale d\'identite, CNI)', format: '10 digits [But it starts with 2 letters and then either 9 or 10 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 12);
            }},
        'JP': {label: 'National identification number "My Number"', format: '12 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 12);
            }},
        'KE': {label: 'National ID Card', format: '8 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 8);
            }},
        'MY': {label: 'NRIC', format: '12 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 12);
            }},
        'MX': {label: 'CURP', format: 'between 10 to 18 characters', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 10, 19);
            }},
        'MA': {label: 'CNIE', format: 'between 5 to 20 characters', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'NI': {label: 'DNI', format: '14 digits (13 numbers, 1 letter)', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 14);
            }},
        'NG': {label: 'NIN', format: '11 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 11);
            }},
        'PK': {label: 'CNIC', format: '13 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 13);
            }},
        'PA': {label: 'Cedula de Identidad', format: '8 digits', required: false,
            validator: (value, format) => {
                return helpers.nonRequiredCharactersLength(value, format, 8);
            }},
        'PY': {label: 'CI', format: 'between 5 to 20 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'PE': {label: 'DNI', format: 'between 8 to 9 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 8, 10);
            }},
        'PH': {label: 'PSN', format: '12 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 12);
            }},
        'RW': {label: 'National Identity Card', format: '16 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 16);
            }},
        'SN': {label: 'CNI or ECOWAS ID Card', format: '13 to 17 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 13, 18);
            }},
        'ZA': {label: 'South African Identity Card', format: '13 digits with full validation', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 13);
            }},
        'TZ': {label: 'National Identity Card', format: 'unique 20 digit national ID number', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 20);
            }},
        'TH': {label: 'Thai Identity Card', format: '13 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersLength(value, format, 13);
            }},
        'TR': {label: 'T.C. Kimlik No.', format: 'between 5 to 20 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 5, 21);
            }},
        'UG': {label: 'Ndaga Muntu- National ID number (NIC)', format: '14 - 17 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 14, 18);
            }},
        'UY': {label: 'CI', format: 'between 6 to 8 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 6, 9);
            }},
        'VN': {label: 'VNID', format: '9 or 12 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 9, 13);
            }},
        'US': {label: 'Social Security Number', format: '8 or 11 digits', required: true,
            validator: (value, format) => {
                return helpers.requiredCharactersBetween(value, format, 8, 12);
            }}
    }
}