import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Momo from '../views/Momo.vue'
import Card from '../views/Card.vue'
import Choice from '../views/Choice.vue'
import store from '../store/index';
import auth from "../middleware/auth";
import guest from "../middleware/guest";
import middlewarePipeline from "../middleware/middlewarePipeline";

const routes = [
    {path: '/', name: 'home', component: Home, meta: {middleware: [guest]}},
    {path: '/choice', name: 'choice', component: Choice, meta: {middleware: [auth]}},
    {path: '/mobile-money', name: 'momo', component: Momo, meta: {middleware: [auth]}},
    {path: '/card', name: 'card', component: Card, meta: {middleware: [auth]}}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeResolve((to, from, next) => {
    // For Middleware
    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router
