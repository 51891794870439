<template>
    <card-page :processing="processing" :content="showContent">
        <template v-slot:content v-if="showContent">
            <iframe class="h-100 w-100 border-0" :src="redirect" v-if="hasRedirect"></iframe>
            <div class="h-100 d-flex p-4" v-else>
                <div class="text-center m-auto">
                    <i class="h1 mdi" :class="{'mdi-alert-circle text-danger': hasErrorStatus, 'mdi-check-circle text-success': hasSuccessStatus}"></i>
                    <p>{{ message }}</p>
                    <a @click="exit()" href="javascript:void(0)" class="btn btn-sm btn-danger"><i class="mdi mdi-close"></i> Close</a>
                </div>
            </div>
        </template>
        <template v-slot:page v-else>
            <p class="text-center pt-4 text-uppercase label">Enter your card information</p>
            <div class="m-4 px-4 pb-4 payment-form">
                <error-alert v-if="error" v-on:close="setError">
                    {{ error }}
                </error-alert>

                <success-alert v-if="success" v-on:close="setSuccess">
                    {{ success }}
                </success-alert>

                <!--Capture card information with dlocal and send token to server to initiate-->
                <Form @submit="validateDLocalCard" v-slot="{ errors }">

                    <form-group input-id="holder-name" :has-error="errors.cardHolder !== undefined">
                        <template v-slot:label>Card Holder</template>
                        <template v-slot:icon>
                            <i class="mdi mdi-account"></i>
                        </template>
                        <template v-slot:input>
                            <field id="holder-name" v-model="cardHolder" name="cardHolder" type="text" class="form-control"
                                   :readonly="processing" placeholder="John Doe"
                                   :rules="validateCardHolder"/>
                        </template>
                        <template v-slot:error>
                            <error-message name="cardHolder"/>
                        </template>
                    </form-group>

                    <form-group input-id="country" :has-error="errors.country !== undefined">
                        <template v-slot:label>Country</template>
                        <template v-slot:icon>
                            <i class="mdi mdi-flag"></i>
                        </template>
                        <template v-slot:input>
                            <field id="country" v-model="country" name="country" as="select" class="form-control"
                                   :readonly="processing" placeholder="Select Country"
                                   :rules="validatedCountry">
                                <option value=""></option>
                                <option v-for="(c, key) in validCountries" :key="`country-${key}`" :value="c.code">{{ c.name }}</option>
                            </field>
                        </template>
                        <template v-slot:error>
                            <error-message name="country"/>
                        </template>
                    </form-group>

                    <div v-if="country">
                        <form-group input-id="holder-document" :has-error="errors.document !== undefined">
                            <template v-slot:label>{{ payerDocumentLabel }}</template>
                            <template v-slot:icon>
                                <i class="mdi mdi-id-card"></i>
                            </template>
                            <template v-slot:input>
                                <field id="holder-document" v-model="document" name="document" type="text" class="form-control"
                                       :readonly="processing"
                                       :rules="validatedDocument" placeholder="PERSONAL ID"/>
                            </template>
                            <template v-slot:error>
                                <error-message name="document"/>
                            </template>
                        </form-group>

                        <div v-if="stateRequired || cityRequired || zipCodeRequired || streetRequired || addressNumberRequired">
                            <form-group v-if="stateRequired" input-id="state" :has-error="errors.state !== undefined">
                                <template v-slot:label>State</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-map-marker"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="state" v-model="address.state" name="state" type="text" class="form-control"
                                           :readonly="processing"
                                           :rules="validateState"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="state"/>
                                </template>
                            </form-group>

                            <form-group v-if="cityRequired" input-id="city" :has-error="errors.city !== undefined">
                                <template v-slot:label>City</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-map-marker"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="city" v-model="address.city" name="city" type="text" class="form-control"
                                           :readonly="processing"
                                           :rules="validateCity"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="city"/>
                                </template>
                            </form-group>

                            <form-group v-if="zipCodeRequired" input-id="zip-code" :has-error="errors.zipCode !== undefined">
                                <template v-slot:label>Zip Code</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-map-marker"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="zip-code" v-model="address.zipCode" name="zipCode" type="text" class="form-control"
                                           :readonly="processing"
                                           :rules="validateZipCode"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="zipCode"/>
                                </template>
                            </form-group>

                            <form-group v-if="streetRequired" input-id="street" :has-error="errors.street !== undefined">
                                <template v-slot:label>Street</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-map-marker"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="street" v-model="address.street" name="street" type="text" class="form-control"
                                           :readonly="processing"
                                           :rules="validateStreet"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="street"/>
                                </template>
                            </form-group>

                            <form-group v-if="addressNumberRequired" input-id="address-number" :has-error="errors.addressNumber !== undefined">
                                <template v-slot:label>Address Number</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-map-marker"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="address-number" v-model="address.addressNumber" name="addressNumber" type="text" class="form-control"
                                           :readonly="processing"
                                           :rules="validateAddressNumber"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="addressNumber"/>
                                </template>
                            </form-group>
                        </div>
                    </div>

                    <form-group v-show="country" input-id="card-field" :has-error="cardError !== null">
                        <template v-slot:label>Credit or Debit Card</template>
                        <template v-slot:field>
                            <div id="card-field" class="form-control my-1"></div>
                        </template>
                        <template v-slot:error>
                            <p v-if="cardError">{{ cardError }}</p>
                        </template>
                    </form-group>

                    <pay-button v-show="country" :class="{
                        disabled: (processing || errors.cardHolder !== undefined || errors.country !== undefined
                         || cardError || (stateRequired && errors.state !== undefined) || (cityRequired && errors.city !== undefined)
                         || (zipCodeRequired && errors.zipCode !== undefined) || (streetRequired && errors.street !== undefined)
                         || (addressNumberRequired && errors.addressNumber !== undefined))
                    }"></pay-button>

                </Form>

                <div class="text-center mt-2" v-if="processing">
                    <progress-loader></progress-loader>
                    <p class="m-0">Processing...</p>
                </div>
            </div>
        </template>
    </card-page>
</template>

<script>
    import {Form, Field, ErrorMessage} from 'vee-validate';
    import {mapGetters, mapActions, mapState} from 'vuex';

    import constants from "../constants/index";
    import CardPage from "./CardPage";
    import PayButton from "./PayButton";
    import ProgressLoader from "./Loader";
    import FormGroup from "./FormGroup";
    import ErrorAlert from "./ErrorAlert";
    import lo from 'lodash';
    import SuccessAlert from "./SuccessAlert";

    export default {
        name: "CardDlocal",
        components: {SuccessAlert, ErrorAlert, ErrorMessage, Field, Form, FormGroup, ProgressLoader, PayButton, CardPage},
        data() {
            return {
                processing: false,
                error: null,
                success: null,
                card: null,
                cardError: null,
                cardHolder: '',
                country: '',
                document: '',
                address: {
                    state: '',
                    city: '',
                    zipCode: '',
                    street: '',
                    addressNumber: ''
                }
            }
        },
        computed: {
            ...mapState(['countries']),
            ...mapGetters({
                payment: constants.GET_PAYMENT,
                response: constants.GET_TRANSACTION,
                driver: constants.GET_DRIVER
            }),
            validCountries() {
                // if (this.payment.currency === 'UGX')
                //     return this.filterCountries('UG');
                //
                // if (this.payment.currency === 'KES')
                //     return this.filterCountries('KE');

                return this.rejectCountries('US');
            },
            hasStatus() {
                return !!(this.response && this.response.data && this.response.data.status);
            },
            rejected() {
                return this.hasStatus && this.response.data.status === 'REJECTED';
            },
            pending() {
                return this.hasStatus && this.response.data.status === 'PENDING';
            },
            paid() {
                return this.hasStatus && this.response.data.status === 'PAID';
            },
            cancelled() {
                return this.hasStatus && this.response.data.status === 'CANCELLED';
            },
            expired() {
                return this.hasStatus && this.response.data.status === 'EXPIRED';
            },
            authorized() {
                return this.hasStatus && this.response.data.status === 'EXPIRED';
            },
            verified() {
                return this.hasStatus && this.response.data.status === 'VERIFIED';
            },
            hasErrorStatus() {
                return this.rejected || this.cancelled || this.expired;
            },
            hasError() {
                return !!(this.response && this.response.data && this.response.data.code && this.response.data.message);
            },
            hasSuccessStatus() {
                return this.paid || this.authorized || this.verified;
            },
            hasRedirect() {
                if (this.pending)
                    return !lo.isEmpty(this.response.data.three_dsecure);

                return false;
            },
            showContent() {
                return this.hasErrorStatus || this.hasSuccessStatus || this.hasRedirect;
            },
            message() {
                if (this.hasError)
                    return this.response.data.message;

                if (this.hasSuccessStatus || this.hasErrorStatus)
                    return this.response.data.status_detail;

                return null;
            },
            redirect() {
                if (this.hasRedirect)
                    return this.response.data.three_dsecure.redirect_url;

                return null;
            },
            payerDocument() {
                if (this.country) {
                    return constants.DLOCAL_PAYER_DOCUMENTS[this.country]
                }

                return null;
            },
            payerDocumentLabel() {
                if (this.payerDocument) {
                    return this.payerDocument.label;
                }

                return null;
            },
            payerDocumentFormat() {
                if (this.payerDocument) {
                    return this.payerDocument.format;
                }

                return null;
            },
            stateRequired() {
                if (this.country) {
                    return lo.includes(['ZA'], this.country)
                }

                return false;
            },
            cityRequired() {
                if (this.country) {
                    return lo.includes(['ZA', 'IN'], this.country)
                }

                return false;
            },
            zipCodeRequired() {
                if (this.country) {
                    return lo.includes(['ZA'], this.country)
                }

                return false;
            },
            streetRequired() {
                if (this.country) {
                    return lo.includes(['ZA', 'IN'], this.country)
                }

                return false;
            },
            addressNumberRequired() {
                if (this.country) {
                    return lo.includes(['ZA', 'IN'], this.country)
                }

                return false;
            }
        },
        methods: {
            ...mapActions('card', {
                pay: constants.INITIATE_CARD_ACTION
            }),
            ...mapActions({
                setTransaction: constants.SET_TRANSACTION,
                exit: constants.CLOSE
            }),
            momo() {
                this.$router.push({name: 'momo'});
            },
            validateCardHolder(value) {
                if (lo.isEmpty(lo.trim(value))) {
                    return 'Please enter a valid card holder\'s name';
                }

                if (value.length < 5) {
                    return 'Cardholder name is too short';
                }

                return true;
            },
            validatedDocument(value) {
                return this.payerDocument.validator(value, lo.upperFirst(this.payerDocumentFormat));
            },
            validatedCountry(value) {
                if (!value) {
                    return 'Please select a country';
                }

                return true;
            },
            validateState(value) {
                if (this.stateRequired) {
                    if (lo.isEmpty(lo.trim(value))) {
                        return 'State is required';
                    }

                    if (value.length > 10) {
                        return 'State should be less that 10 characters';
                    }
                }

                return true;
            },
            validateCity(value) {
                if (this.cityRequired) {
                    if (lo.isEmpty(lo.trim(value))) {
                        return 'City is required';
                    }

                    if (value.length > 100) {
                        return 'City should be less that 100 characters';
                    }
                }

                return true;
            },
            validateZipCode(value) {
                if (this.zipCodeRequired) {
                    if (lo.isEmpty(lo.trim(value))) {
                        return 'Zip code is required';
                    }

                    if (value.length > 10) {
                        return 'Zip code should be less that 10 characters';
                    }
                }

                return true;
            },
            validateStreet(value) {
                if (this.streetRequired) {
                    if (lo.isEmpty(lo.trim(value))) {
                        return 'Street is required';
                    }

                    if (value.length > 100) {
                        return 'Street should be less that 100 characters';
                    }
                }

                return true;
            },
            validateAddressNumber(value) {
                if (this.addressNumberRequired) {
                    if (lo.isEmpty(lo.trim(value))) {
                        return 'Address number is required';
                    }

                    if (value.length > 20) {
                        return 'Address number should be less that 20 characters';
                    }
                }

                return true;
            },
            initiate() {
                if (!this.processing) {
                    this.processing = true;
                    this.pay(this.payment)
                        .then((response) => {
                            this.setTransaction(response.data);
                        })
                        .then(() => {
                            this.processing = false;
                        })
                        .catch(() => {
                            this.processing = false;
                            this.setError("An error occurred, please try again!");
                        });
                }
            },
            setError(error) {
                this.error = error;
                this.success = null;
            },
            setSuccess(success) {
                this.success = success;
                this.error = null;
            },
            initializeDlocal() {
                this.address = {
                    state: '',
                    city: '',
                    zipCode: '',
                    street: '',
                    addressNumber: ''
                };

                this.cardHolder = this.payment.fullname;

                let fields = this.$dlocal().fields({
                    'locale': 'en', 'country': this.country
                });

                let options = {
                    style: {
                        base: {}
                    },
                    classes: {
                        base: 'form-control'
                    },
                    placeholder: {
                        number: '0000 0000 0000 0000',
                        cvv: "123",
                        expiration: 'MM/YY'
                    }
                };

                this.card = fields.create('card', options);
                this.card.mount(document.getElementById('card-field'));

                this.card.addEventListener('change', (event) => {
                    console.log("CARD CHANGE EVENT");
                    console.log(event);
                    if (event.error) {
                        this.cardError = event.error.message;
                    } else {
                        this.cardError = null;
                    }
                });

                this.card.on('brand', (event) => {
                    console.log("CARD BRAND EVENT");
                    console.log(event);
                    this.cardError = null;
                });
            },
            validateDLocalCard() {
                if (!this.processing) {
                    this.processing = true;

                    this.$dlocal().createToken(this.card, {name: this.cardHolder, currency: this.payment.currency})
                        .then((response) => {
                            console.log("TOKEN");
                            console.log(response);
                            this.processing = false;
                            this.pay({
                                ...this.payment,
                                card_token: response.token,
                                holder_name: this.cardHolder,
                                country: this.country,
                                document: this.document,
                                address: {
                                    state: this.address.state,
                                    city: this.address.city,
                                    zip_code: this.address.zipCode,
                                    street: this.address.street,
                                    number: this.address.addressNumber
                                }
                            }).then((response) => {
                                this.setTransaction(response.data);
                            }).then(() => {
                                if (this.hasError) {
                                    this.setError(this.message);
                                }
                            }).then(() => {
                                this.processing = false;
                            }).catch(() => {
                                this.processing = false;
                                this.setError("An error occurred, please try again!");
                            });
                        })
                        .catch((response) => {
                            console.log("TOKEN ERROR");
                            console.log(response);
                            this.processing = false;
                            this.cardError = response.error.message;
                        });
                }
            },
            unMount() {
                if (this.card)
                    this.card.unmount();
            },
            filterCountries(code) {
                return lo.filter(this.countries, (country) => {
                    return country.code === code;
                });
            },
            rejectCountries(code) {
                return lo.reject(this.countries, (country) => {
                    return country.code === code;
                });
            }
        },
        watch: {
            country() {
                if (this.country) {
                    this.unMount();
                    this.initializeDlocal();
                } else {
                    this.unMount();
                }

            }
        }
    }
</script>

<style scoped>

</style>