<template>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <div class="main">
                <router-view/>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</template>

<style>

</style>
