<template>
    <page-template>
        <template v-slot:card>
            <div class="card-body d-flex border-bottom-radius border-bottom-radius">
                <template v-if="processing">
                    <loader class="m-auto"/>
                </template>
                <template v-else>
                    <div class="text-center m-auto">
                        <i class="mdi mdi-alert-circle text-danger h1"></i>
                        <p>Unauthorized Access!</p>
                        <a v-if="origin" @click="exit('Close Window?')" href="javascript:void(0)" class="btn btn-sm btn-danger"><i class="mdi mdi-close"></i> Close</a>
                    </div>
                </template>
            </div>
        </template>
    </page-template>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import PageTemplate from "../components/PageTemplate";
    import Loader from "../components/Loader";
    import constants from "../constants";
    export default {
        name: 'HomeView',
        data() {
            return {
                processing: false
            }
        },
        components: {
            Loader,
            PageTemplate
        },
        created() {
            if (this.$route.query.origin) {
                this.setOrigin(this.$route.query.origin);
            }

            if (this.$route.query.payment) {
                this.setPayment(this.$route.query.payment);
            }

            this.check();
        },
        computed: {
            ...mapState(['origin', 'payment']),
        },
        methods: {
            ...mapActions({
                setOrigin: constants.SET_ORIGIN,
                setPayment: constants.SET_PAYMENT,
                exit: constants.CLOSE,
                setFees: constants.SET_FEES,
                getCountries: constants.GET_COUNTRIES,
                setDefaultCardDriver: constants.SET_DEFAULT_CARD_DRIVER,
                setDefaultMomoDriver: constants.SET_DEFAULT_MOMO_DRIVER
            }),
            ...mapActions('auth', {
                attempt: constants.ATTEMPT
            }),
            async check() {
                if (!this.processing && this.payment) {
                    this.processing = true;
                    try {
                        await this.attempt(this.payment.token);
                        await this.setFees();
                        await this.getCountries();
                        await this.setDefaultCardDriver();
                        await this.setDefaultMomoDriver();

                        this.$router.push({name: 'choice'});
                        this.processing = false;
                    } catch (e) {
                        this.processing = false;
                    }
                }
            }
        }
    }
</script>

