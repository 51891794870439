import store from '../index';
import axios from 'axios';
import constants from '../../constants';

store.subscribe((mutation) => {

    switch (mutation.type) {

        case `auth/${constants.SET_TOKEN}`:

            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
            } else {
                axios.defaults.headers.common['Authorization'] = null;
            }
            break;
    }

});