<template>
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Success!</strong> <slot></slot>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
    export default {
        name: "SuccessAlert",
        methods: {
            close() {
                this.$emit('close', null);
            }
        }
    }
</script>

<style scoped>

</style>