<template>
    <page-template>
        <template v-slot:body>
            <div class="p-4 d-flex h-100">
                <div class="m-auto" align="center">
                    <h2 class="my-4">How would<br />you like to pay?</h2>
                    <div class="w-25 bg-primary pb-1"></div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="card-footer p-0 top">
                <div class="list-group list-group-flush">
                    <momo-method></momo-method>
                    <card-method class="border-bottom-radius"></card-method>
                </div>
            </div>
        </template>
    </page-template>
</template>

<script>

    import PageTemplate from "../components/PageTemplate";
    import MomoMethod from "../components/MomoMethod";
    import CardMethod from "../components/CardMethod";
    export default {
        name: 'PaymentChoice',
        components: {
            CardMethod,
            MomoMethod,
            PageTemplate
        }
    }
</script>

