export default {
    install: (app, options) => {
        const checkoutType = options.checkout_type;

        app.mixin({
            created() {
                const cellulant = 'https://cdn.cellulant.africa/js/tingg-checkout-library.js';
                const script = document.createElement('script');
                script.src = cellulant;
                // Only run if flw script has not been added to the body
                if (!document.querySelector(`[src="${cellulant}"]`)) {
                    document.body.appendChild(script);
                }
            },
            computed: {
                cellulantAccesskey() {
                    return options.access_key;
                },
                cellulantCheckoutUrl() {
                    if (options.environment === 'sandbox')
                        return 'https://online.uat.tingg.africa/testing/express/checkout';

                    return 'https://checkout.tingg.africa/express/checkout';
                }
            }
        });

        // inject a globally available $dlocal() method
        app.config.globalProperties.$cellulantButton = (text, buttonClass) => {
            return window.Tingg.renderPayButton({
                text: text,
                className: buttonClass,
                checkoutType
            });
        };

        app.config.globalProperties.$cellulantCheckout = (encrypted_payload) => {
            return window.Tingg.renderCheckout({
                test: options.environment === 'sandbox',
                checkout_type: checkoutType,
                checkout_payload: {
                    encrypted_payload: encrypted_payload,
                    access_key: options.access_key
                },
            });
        };
    }
}