<template>
    <div>
        <div class="form-group border px-2 pb-2 border-top-radius border-bottom-radius bg-white m-0" :class="{'border-danger': hasError}">
            <label :for="inputId" class="text-uppercase m-0" :class="{'text-danger': hasError}"><slot name="label"></slot></label>
            <slot name="field">
                <div class="d-flex">
                    <div class="my-auto" :class="{'text-danger': hasError}">
                        <slot name="icon"></slot>
                    </div>
                    <div class="my-auto ml-2 w-100">
                        <slot name="input"></slot>
                    </div>
                </div>
            </slot>
        </div>
        <p class="text-danger error">
            <slot name="error"></slot>
        </p>
    </div>
</template>

<script>
    export default {
        name: "FormGroup",
        props: {
            hasError: {
                type: Boolean,
                default: false
            },
            inputId: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>