<template>
    <a @click="momo" href="javascript:void(0)" class="list-group-item list-group-item-action d-flex" :class="{'disabled bg-light': inactive || !payable}">
        <span class="my-auto">
            <span class="text-muted" style="font-weight: lighter">Pay with </span>
            <span class="text-dark" style="font-weight: normal">Mobile Money</span>
            <span class="text-muted ml-2" style="font-weight: lighter" v-if="!payable">(Not applicable)</span>
        </span>
        <i class="mdi mdi-chevron-up my-auto ml-auto"></i>
    </a>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import constants from "../constants";
    import lo from 'lodash';
    export default {
        name: "MomoMethod",
        props: {
            inactive: {
                type: Boolean,
                defalult: false
            }
        },
        computed: {
            ...mapGetters({
                payment: constants.GET_PAYMENT
            }),
            payable() {
                return lo.includes(constants.MOMO_CURRENCIES, lo.upperCase(this.payment.currency))
            }
        },
        methods: {
            ...mapActions({
                setFeesType: constants.SET_FEES_TYPE
            }),
            momo() {
                this.setFeesType(constants.DEFAULT_FEES_TYPE).then(() => {
                    this.$router.push({name: 'momo'});
                });
            }
        }
    }
</script>

<style scoped>

</style>