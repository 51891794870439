<template>
    <page-template :enable-exit="!processing">
        <template v-slot:content v-if="content">
            <div class="card-body p-0">
                <slot name="content"></slot>
            </div>
        </template>
        <template v-slot:body v-if="!content">
            <div class="list-group list-group-flush border-bottom bottom">
                <div class="list-group-item d-flex">
                    <span class="my-auto">
                        <span class="text-muted" style="font-weight: lighter">Pay with </span>
                        <span class="text-dark" style="font-weight: normal">Mobile Money</span>
                    </span>
                    <i class="mdi mdi-chevron-down my-auto ml-auto"></i>
                </div>
            </div>
            <slot name="page"></slot>
        </template>
        <template v-slot:footer v-if="!content">
            <div class="card-footer p-0 top">
                <div class="list-group list-group-flush">
                    <card-method class="border-bottom-radius" :class="{disabled: processing}"></card-method>
                </div>
            </div>
        </template>
    </page-template>
</template>

<script>
    import PageTemplate from "./PageTemplate";
    import CardMethod from "./CardMethod";
    export default {
        name: "MomoPayment",
        components: {CardMethod, PageTemplate},
        props: {
            processing: {
                type: Boolean,
                default: false
            },
            content: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>