<template>
    <card-page :processing="processing" :content="showContent">
        <template v-slot:content v-if="showContent">
            <iframe class="h-100 w-100 border-0" :src="redirect" v-if="hasRedirect"></iframe>
            <div class="h-100 d-flex p-4" v-else>
                <div class="text-center m-auto">
                    <i class="h1 mdi" :class="{'mdi-alert-circle text-danger': hasError, 'mdi-check-circle text-success': hasSuccess}"></i>
                    <p>{{ message }}</p>
                    <a @click="exit()" href="javascript:void(0)" class="btn btn-sm btn-danger"><i class="mdi mdi-close"></i> Close</a>
                </div>
            </div>
        </template>
        <template v-slot:page v-else>
            <p class="text-center pt-4 text-uppercase label">Initiate a card payment</p>
            <div class="m-4 px-4 pb-4 payment-form">
                <error-alert v-if="error" v-on:close="setError">
                    {{ error }}
                </error-alert>

                <div class="text-center mb-2" v-if="processing">
                    <progress-loader></progress-loader>
                    <p class="m-0">Processing...</p>
                </div>
                <div v-show="!processing">
                    <slot></slot>
                    <pay-button @click="initiate" :class="{disabled: processing}"></pay-button>
                </div>
            </div>
        </template>
    </card-page>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    import constants from "../constants/index";
    import CardPage from "./CardPage";
    import PayButton from "./PayButton";
    import ProgressLoader from "./Loader";
    import ErrorAlert from "./ErrorAlert";

    export default {
        name: "CardFlutterwave",
        components: {ErrorAlert, ProgressLoader, PayButton, CardPage},
        data() {
            return {
                processing: false,
                error: null
            }
        },
        computed: {
            ...mapGetters({
                payment: constants.GET_PAYMENT,
                response: constants.GET_TRANSACTION
            }),
            hasError() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'error');
            },
            hasSuccess() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'success' && !this.response.data.data);
            },
            hasRedirect() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'success' && this.response.data.data && this.response.data.data.link);
            },
            showContent() {
                return this.hasError || this.hasSuccess || this.hasRedirect;
            },
            message() {
                if (this.hasError || this.hasSuccess)
                    return this.response.data.message;

                return null;
            },
            redirect() {
                if (this.hasRedirect)
                    return this.response.data.data.link;

                return null;
            }
        },
        methods: {
            ...mapActions('card', {
                pay: constants.INITIATE_CARD_ACTION
            }),
            ...mapActions({
                setTransaction: constants.SET_TRANSACTION,
                exit: constants.CLOSE
            }),
            momo() {
                this.$router.push({name: 'momo'});
            },
            initiate() {
                if (!this.processing) {
                    this.processing = true;
                    this.pay(this.payment)
                        .then((response) => {
                            this.setTransaction(response.data);
                        })
                        .then(() => {
                            this.processing = false;
                        })
                        .catch(() => {
                            this.processing = false;
                            this.setError("An error occurred, please try again!");
                        });
                }
            },
            setError(error) {
                this.error = error;
            }
        }
    }
</script>

<style scoped>

</style>