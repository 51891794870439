<template>
    <card-page :processing="processing" :content="hasRedirect">
        <template v-slot:content v-if="showContent">
            <iframe class="h-100 w-100 border-0" :src="redirect" v-if="hasRedirect"></iframe>
            <div class="h-100 d-flex p-4" v-else>
                <div class="text-center m-auto">
                    <i class="h1 mdi" :class="{'mdi-alert-circle text-danger': hasError, 'mdi-check-circle text-success': hasSuccess}"></i>
                    <p>{{ message }}</p>
                    <a @click="exit()" href="javascript:void(0)" class="btn btn-sm btn-danger"><i class="mdi mdi-close"></i> Close</a>
                </div>
            </div>
        </template>
        <template v-slot:page v-else>
            <p class="text-center pt-4 text-uppercase label">Enter your card information</p>
            <div class="m-4 px-4 pb-4 payment-form">
                <error-alert v-if="error" v-on:close="setError">
                    {{ error }}
                </error-alert>
                <Form @submit="validate" v-slot="{ values, errors }">
                    <form-group input-id="card-number" :has-error="errors.cardNumber !== undefined">
                        <template v-slot:label>Card Number<span v-if="cardType(values.cardNumber)"> - {{ cardType(values.cardNumber) }}</span></template>
                        <template v-slot:icon>
                            <i class="mdi mdi-credit-card"></i>
                        </template>
                        <template v-slot:input>
                            <field id="card-number" v-model="cardNumber" name="cardNumber" type="text" class="form-control"
                                   :readonly="processing" placeholder="0000 0000 0000 0000"
                                   :rules="validateCardNumber"/>
                        </template>
                        <template v-slot:error>
                            <error-message name="cardNumber"/>
                        </template>
                    </form-group>

                    <div class="row">
                        <div class="col-8">
                            <form-group input-id="expiry"
                                        :has-error="errors.expiryMonth !== undefined || errors.expiryYear !== undefined">
                                <template v-slot:label>Expiry (Month/Year)</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-calendar-blank"></i>
                                </template>
                                <template v-slot:input>
                                    <div class="input-group m-0" id="expiry">
                                        <field v-model="expiryMonth" name="expiryMonth" type="text" placeholder="MM"
                                               class="form-control text-center"
                                               :readonly="processing" :rules="validateExpiryMonth"/>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text p-0 bg-white border-0">
                                                <i class="mdi mdi-slash-forward"></i>
                                            </span>
                                        </div>
                                        <field v-model="expiryYear" name="expiryYear" type="text"
                                               class="form-control text-center" placeholder="YY"
                                               :readonly="processing" :rules="validateExpiryYear"/>
                                    </div>
                                </template>
                                <template v-slot:error>
                                    <p class="m-0">
                                        <error-message name="expiryMonth"/>
                                    </p>
                                    <p class="m-0">
                                        <error-message name="expiryYear"/>
                                    </p>
                                </template>
                            </form-group>
                        </div>
                        <div class="col-4">
                            <form-group input-id="cvv" :has-error="errors.cvv !== undefined">
                                <template v-slot:label>CVV</template>
                                <template v-slot:icon>
                                    <i class="mdi mdi-numeric"></i>
                                </template>
                                <template v-slot:input>
                                    <field id="cvv" v-model="cvv" name="cvv" type="text" class="form-control"
                                           :readonly="processing" placeholder="123" :rules="validateCvv"/>
                                </template>
                                <template v-slot:error>
                                    <error-message name="cvv"/>
                                </template>
                            </form-group>
                        </div>
                    </div>

                    <pay-button :class="{
                        disabled: (processing || errors.expiryMonth !== undefined || errors.expiryYear !== undefined
                        || errors.expiryMonth !== undefined || errors.expiryYear !== undefined || errors.cvv !== undefined)
                    }"></pay-button>

                </Form>

                <div class="text-center mt-2" v-if="processing">
                    <progress-loader></progress-loader>
                    <p class="m-0">Processing...</p>
                </div>
            </div>
        </template>
    </card-page>
</template>

<script>
    import valid from 'card-validator';
    import {Form, Field, ErrorMessage} from 'vee-validate';
    import {mapGetters, mapActions, createNamespacedHelpers} from 'vuex';

    const {mapMutations} = createNamespacedHelpers('card');
    import constants from "../constants/index";
    import CardPage from "./CardPage";
    import PayButton from "./PayButton";
    import ProgressLoader from "./Loader";
    import FormGroup from "./FormGroup";
    import ErrorAlert from "./ErrorAlert";

    export default {
        name: "CardDirect",
        components: {ErrorAlert, ErrorMessage, Field, Form, FormGroup, ProgressLoader, PayButton, CardPage},
        data() {
            return {
                processing: false,
                error: null
            }
        },
        computed: {
            ...mapGetters({
                payment: constants.GET_PAYMENT,
                response: constants.GET_TRANSACTION
            }),
            cardNumber: {
                get() {
                    return this.$store.state['card/card_number'];
                },
                set(value) {
                    this.updateCardNumber(value);
                }
            },
            expiryMonth: {
                get() {
                    return this.$store.state['card/month'];
                },
                set(value) {
                    this.updateMonth(value);
                }
            },
            expiryYear: {
                get() {
                    return this.$store.state['card/year'];
                },
                set(value) {
                    this.updateYear(value);
                }
            },
            cvv: {
                get() {
                    return this.$store.state['card/cvv'];
                },
                set(value) {
                    this.updateCvv(value);
                }
            },
            hasError() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'error');
            },
            hasSuccess() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'success' && !this.response.data.data);
            },
            hasRedirect() {
                return !!(this.response && this.response.data && this.response.data.status
                    && this.response.data.status === 'success' && this.response.data.data && this.response.data.data.link);
            },
            showContent() {
                return this.hasError || this.hasSuccess || this.hasRedirect;
            },
            message() {
                if (this.hasError || this.hasSuccess)
                    return this.response.data.message;

                return null;
            },
            redirect() {
                if (this.hasRedirect)
                    return this.response.data.data.link;

                return null;
            }
        },
        methods: {
            ...mapMutations({
                updateCardNumber: constants.UPDATE_CARD_NUMBER,
                updateMonth: constants.UPDATE_MONTH,
                updateYear: constants.UPDATE_YEAR,
                updateCvv: constants.UPDATE_CVV
            }),
            ...mapActions('card', {
                pay: constants.PAY_ACTION
            }),
            ...mapActions({
                setTransaction: constants.SET_TRANSACTION,
                exit: constants.CLOSE
            }),
            momo() {
                this.$router.push({name: 'momo'});
            },
            cardType(value) {
                let validator = valid.number(value);

                if (!validator.card) {
                    return null;
                }

                return validator.card.type;
            },
            validateCardNumber(value) {
                if (!value) {
                    return 'Please enter a valid credit card number';
                }

                let validator = valid.number(value);

                if (!validator.isValid) {
                    return 'Please enter a valid credit card number';
                }

                return true;
            },
            validateExpiryMonth(value) {
                if (!value) {
                    return 'Please enter a valid month';
                }

                let validator = valid.expirationMonth(value);

                if (!validator.isValid) {
                    return 'Please enter a valid month';
                }

                return true;
            },
            validateExpiryYear(value) {
                if (!value) {
                    return 'Please enter a valid year';
                }

                let validator = valid.expirationYear(value);

                if (!validator.isValid) {
                    return 'Please enter a valid year';
                }

                return true;
            },
            validateCvv(value) {
                if (!value) {
                    return 'Please enter a valid cvv';
                }

                let validator = valid.cvv(value);

                if (!validator.isValid) {
                    return 'Please enter a valid cvv';
                }

                return true;
            },
            validate() {
                if (!this.processing) {
                    this.processing = true;
                    this.pay(this.payment)
                        .then((response) => {
                            this.setTransaction(response.data);
                        })
                        .then(() => {
                            this.processing = false;
                        })
                        .catch(() => {
                            this.processing = false;
                            this.setError("An error occurred, please try again!");
                        });
                }
            },
            setError(error) {
                this.error = error;
            }
        }
    }
</script>

<style scoped>

</style>