import { loadScript } from "@paypal/paypal-js";
export default {
    install: (app, options) => {
        let paypal;

        app.mixin({
            async created() {
                try {
                    paypal = await loadScript({ "client-id": options.client_id });
                } catch (error) {
                    console.error("failed to load the PayPal JS SDK script", error);
                }
            }
        });

        // inject a globally available $paypal() method
        app.config.globalProperties.$paypal = () => {
            if (paypal) {
                return paypal;
            }

            return null;
        }
    }
}