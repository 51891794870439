<template>
    <card-page>
        <template v-slot:page>
            <div class="m-4 px-4 pb-4 payment-form">
                <error-alert v-if="error" v-on:close="setError">
                    {{ error }}
                </error-alert>

                <success-alert v-if="success" v-on:close="setSuccess">
                    {{ success }}
                </success-alert>

                <div class="text-center" v-if="processing">
                    <progress-loader></progress-loader>
                    <p class="m-0">Processing...</p>
                </div>

                <div v-show="!processing">
                    <slot></slot>
                    <div ref="paypal"></div>
                </div>
            </div>
        </template>
    </card-page>
</template>

<script>
    import {mapGetters, mapActions, mapState} from 'vuex';
    import CardPage from "./CardPage";
    import constants from "../constants";
    import ErrorAlert from "./ErrorAlert";
    import SuccessAlert from "./SuccessAlert";
    import ProgressLoader from "./Loader";
    export default {
        name: "PayPal",
        components: {ProgressLoader, SuccessAlert, ErrorAlert, CardPage},
        data() {
            return {
                processing: false,
                error: null,
                success: null,
                description: 'Tripesa PayPal payment',
                style: {
                    shape: 'pill', //or rect
                    color: 'gold',
                    layout: 'vertical',
                    label: 'paypal',
                }
            }
        },
        mounted() {
            this.initialize();
        },
        computed: {
            ...mapState(['card_driver']),
            ...mapGetters({
                payment: constants.GET_PAYMENT,
                response: constants.GET_TRANSACTION,
                driver: constants.GET_DRIVER
            })
        },
        methods: {
            ...mapActions('card', {
                pay: constants.INITIATE_CARD_ACTION
            }),
            ...mapActions({
                setTransaction: constants.SET_TRANSACTION,
                exit: constants.CLOSE
            }),
            initialize () {
                if (this.$paypal())
                    this.$paypal().Buttons({
                        style: this.style,
                        createOrder: this.createOrder,
                        onApprove: this.onApprove,
                        onError: this.onError
                    }).render(this.$refs.paypal);
            },
            createOrder(data, actions) {
                return actions.order.create({
                    purchase_units: [
                        {
                            description: this.description,
                            amount: {
                                currency_code: this.payment.currency,
                                value: this.payment.amount
                            }
                        }
                    ]
                });
            },
            async onApprove(data, actions) {
                return actions.order.capture().then(this.onResponse);
            },
            onError(error) {
                console.log(error);
            },
            onResponse(orderData) {
                // console.log('Transaction results', orderData, JSON.stringify(orderData, null, 2));

                this.processing = true;
                this.pay({
                    ...this.payment,
                    paypal: orderData,
                }).then((response) => {
                    this.setTransaction(response.data);
                }).then(() => {
                    this.processing = false;
                    this.setSuccess("Payment has been successfully processed!");
                }).catch(() => {
                    this.processing = false;
                    this.setError("An error occurred, please try again!");
                });
            },
            setError(error) {
                this.error = error;
                this.success = null;
            },
            setSuccess(success) {
                this.success = success;
                this.error = null;
            }
        }
    }
</script>

<style scoped>

</style>