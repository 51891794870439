export default {
    install: (app, options) => {
        app.mixin({
            created() {
                const dLocal = options.smart_fields_url;
                const script = document.createElement('script');
                script.src = dLocal;
                // Only run if flw script has not been added to the body
                if (!document.querySelector(`[src="${dLocal}"]`)) {
                    document.body.appendChild(script);
                }
            }
        });

        // inject a globally available $dlocal() method
        app.config.globalProperties.$dlocal = () => {
            return window.dlocal(options.api_key);
        }
    }
}