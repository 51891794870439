import api from "../../api";
import helpers from "../../helpers";

export default {
    namespaced: true,
    state: {
        phone_number: null
    },
    getters: {},
    mutations: {
        UPDATE_PHONE_NUMBER(state, number) {
            state.phone_number = number;
        }
    },
    actions: {
        PAY_ACTION({state}, payload) {
            return api.momo(helpers.encrypt({...payload, phone_number: state.phone_number}));
        }
    }
}