import api from '../../api';
import helpers from '../../helpers';

export default {
    namespaced: true,
    state: {
        card_number: null,
        month: null,
        year: null,
        cvv: null
    },
    getters: {},
    mutations: {
        UPDATE_CARD_NUMBER(state, number) {
            state.card_number = number;
        },
        UPDATE_MONTH(state, month) {
            state.month = month;
        },
        UPDATE_YEAR(state, year) {
            state.year = year;
        },
        UPDATE_CVV(state, cvv) {
            state.cvv = cvv;
        }
    },
    actions: {
        PAY_ACTION({state}, payload) {
            return api.card(helpers.encrypt({
                ...payload, card_number: state.card_number, expiry_month: state.month, expiry_year: state.year, cvv: state.cvv
            }));
        },
        INITIATE_CARD_ACTION(context, payload) {
            return api.card(helpers.encrypt(payload));
        }
    }
}