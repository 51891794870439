import constants from "../constants";
import router from '../router'
export default function auth({next, store}) {
    if (!store.getters[`auth/${constants.AUTHENTICATED}`]) {
        return router.push({
            name: 'home'
        })
    }

    return next()
}