import constants from "../constants";
import router from '../router'
export default function guest({next, store}) {
    if (store.getters[`auth/${constants.AUTHENTICATED}`]) {
        return router.push({
            name: 'choice'
        })
    }

    return next()
}