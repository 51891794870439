<template>
	<card-page :processing="processing">
		<template v-slot:page>
			<div class="m-4 px-4 pb-4 payment-form">
				<error-alert v-if="error" v-on:close="setError">
					{{ error }}
				</error-alert>

				<success-alert v-if="success" v-on:close="setSuccess">
					{{ success }}
				</success-alert>

				<div class="text-center" v-if="processing">
					<progress-loader></progress-loader>
					<p class="m-0">Processing...</p>
				</div>

				<div v-show="!processing">
					<slot></slot>
					<Form @submit="initiate" v-slot="{ values, errors }">
						<form-group input-id="phone-number" :has-error="errors.phoneNumber !== undefined">
							<template v-slot:label>
								Phone number (With country code)
								<span v-if="countryCode(values.phoneNumber)">
                                - <span :class="`fi fi-${lower(countryCode(values.phoneNumber))}`"></span>
                            </span>
							</template>
							<template v-slot:icon>
								<i class="mdi mdi-phone-outline"></i>
							</template>
							<template v-slot:input>
								<field id="phone-number" name="phoneNumber" v-model="phoneNumber" type="text" class="form-control" :readonly="processing" placeholder="256785000000" :rules="validatePhoneNumber"/>
							</template>
							<template v-slot:error>
								<error-message name="phoneNumber"/>
							</template>
						</form-group>

						<pay-button :class="{disabled: processing || errors.phoneNumber !== undefined}"></pay-button>
					</Form>
				</div>
			</div>
		</template>
	</card-page>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import CardPage from "./CardPage";
import constants from "../constants";
import ErrorAlert from "./ErrorAlert";
import SuccessAlert from "./SuccessAlert";
import ProgressLoader from "./Loader";
import api from "../api";
import lo from 'lodash';
import PayButton from "@/components/PayButton";
import FormGroup from "@/components/FormGroup";
import {ParseError, parsePhoneNumberWithError} from "libphonenumber-js";
import {ErrorMessage, Field, Form} from "vee-validate";

const getCountryISO3 = require("country-iso-2-to-3");
export default {
	name: "TingCellulant",
	components: {FormGroup, PayButton, ProgressLoader, SuccessAlert, ErrorAlert, CardPage, ErrorMessage, Field, Form},
	data() {
		return {
			processing: false,
			error: null,
			success: null,
			phoneNumber: ''
		}
	},
	mounted() {
		this.initialize();
		if (this.payment && this.payment.phone_number) {
			this.phoneNumber = this.payment.phone_number;
		}
	},
	computed: {
		...mapState(['card_driver', 'country']),
		...mapGetters({
			payment: constants.GET_PAYMENT,
			response: constants.GET_TRANSACTION,
			driver: constants.GET_DRIVER
		}),
		button() {
			return 'ting-cellulant';
		},
		payable() {
			return `PAY ${this.payment.currency} ${this.commas(this.payment.amount)}`
		},
		hasRedirect() {
			if (this.response && this.response.status !== null)
				return false;

			return !!this.response && this.response.data && this.response.data.encrypted;
		},
		redirect() {
			if (this.hasRedirect) {
				return `${this.cellulantCheckoutUrl}?access_key=${this.cellulantAccesskey}&encrypted_payload=${this.response.data.encrypted}`;
			}

			return null;
		}
	},
	methods: {
		...mapActions('card', {
			pay: constants.INITIATE_CARD_ACTION
		}),
		...mapActions({
			setTransaction: constants.SET_TRANSACTION,
			exit: constants.CLOSE
		}),
		initialize() {
			// this.$cellulantButton(this.payable, this.button);
		},
		setError(error) {
			this.error = error;
			this.success = null;
		},
		setSuccess(success) {
			this.success = success;
			this.error = null;
		},
		commas(num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		},
		lower(value) {
			return lo.lowerCase(value);
		},
		countryCode(value) {
			try {
				const phoneNumber = parsePhoneNumberWithError(`+${lo.replace(value, "+", "")}`);

				if (phoneNumber.isValid()) {
					return phoneNumber.country;
				}

			} catch (error) {
				return null;
			}

			return null;
		},
		validatePhoneNumber(value) {
			if (!value) {
				return 'Please enter a valid phone number';
			}

			try {
				const phoneNumber = parsePhoneNumberWithError(`+${lo.replace(value, "+", "")}`);

				if (!phoneNumber.isValid()) {
					return 'Please enter a valid phone number';
				}

			} catch (error) {
				if (error instanceof ParseError) {
					// Not a phone number, non-existent country, etc.
					return lo.capitalize(lo.replace(lo.kebabCase(error.message), '-', " "));
				} else {
					throw error
				}
			}

			return true;
		},
		initiate() {
			this.processing = true;
			this.pay({
				...this.payment,
				phone_number: this.phoneNumber,
				cellullant: 'card',
				country: getCountryISO3(this.country)
			}).then((response) => {
				this.setTransaction(response.data);
			})
				.then(() => {
					if (this.hasRedirect) {
						window.open(this.redirect, '_blank');
					}
					// this.$cellulantCheckout(this.response.data.encrypted);
				})
				.then(() => {
					if (this.response && this.response.id) {
						let checkStatus = lo.debounce(this.checkTransactionStatus, 5000);
						checkStatus();
					}
				})
				.catch(() => {
					this.processing = false;
					this.setError("An error occurred, please try again!");
				});
		},
		checkTransactionStatus() {
			if (this.response && this.response.id) {
				api.showTransaction(this.response.id)
					.then((response) => {
						this.setTransaction(response.data);
					})
					.then(() => {
						if (this.response.status !== null) {
							if (this.response.status === 'successful') {
								this.processing = false;
								this.setSuccess("The payment was completed successfully");
							} else {
								this.processing = false;
								this.setError('An error occurred, please try again!');
							}
						}
					})
					.then(() => {
						if (this.processing) {
							let checkStatus = lo.debounce(this.checkTransactionStatus, 5000);
							checkStatus();
						}
					})
					.catch(() => {
						this.processing = false;
						this.setError("An error occurred, please try again!");
					});
			}
		}
	}
}
</script>

<style scoped>

</style>