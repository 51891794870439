import lo from "lodash";

const CryptoJS = require("crypto-js");
const UUID = "c31433cc-1295-44aa-aa7a-f2a0e647a78c";
const FORMAT = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
        return cipherParams;
    }
}
export default {
    encrypt(payload) {
        return CryptoJS.AES.encrypt(JSON.stringify(payload), UUID, {format: FORMAT}).toString();
    },
    decrypt(payload) {
        return JSON.parse(CryptoJS.AES.decrypt(payload, UUID, {format: FORMAT}).toString(CryptoJS.enc.Utf8));
    },
    decrypT (encoded) {
        let salt = UUID;
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
    },
    requiredDigitsBetweenOrLength(value, format, start, end, length) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return format;
        }

        if (isNaN(lo.toNumber(value))) {
            return format;
        }

        if (!lo.inRange(value.length, start, end) && value.length !== length ) {
            return format;
        }

        return true;
    },
    requiredDigitsBetween(value, format, start, end) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return format;
        }

        if (isNaN(lo.toNumber(value))) {
            return format;
        }

        if (!lo.inRange(value.length, start, end)) {
            return format;
        }

        return true;
    },
    requiredDigitsLength(value, format, length) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return format;
        }

        if (isNaN(lo.toNumber(value))) {
            return format;
        }

        if (value.length !== length ) {
            return format;
        }

        return true;
    },
    nonRequiredDigitsLength(value, format, length) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return true;
        }

        if (isNaN(lo.toNumber(value))) {
            return format;
        }

        if (value.length !== length ) {
            return format;
        }

        return true;
    },
    nonRequiredCharactersLength(value, format, length) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return true;
        }

        if (value.length !== length ) {
            return format;
        }

        return true;
    },
    requiredCharactersBetween(value, format, start, end) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return format;
        }

        if (!lo.inRange(value.length, start, end)) {
            return format;
        }

        return true;
    },
    requiredCharactersLength(value, format, length) {
        value = lo.trim(value);

        if (lo.isEmpty(value)) {
            return format;
        }

        if (value.length !== length ) {
            return format;
        }

        return true;
    }
}