<template>
    <a @click="card" href="javascript:void(0)" class="list-group-item list-group-item-action d-flex">
        <span class="my-auto">
            <span class="text-muted" style="font-weight: lighter">Pay with your </span>
            <span class="text-dark" style="font-weight: normal">Card</span>
        </span>
        <i class="mdi mdi-chevron-up my-auto ml-auto"></i>
    </a>
</template>

<script>
    import { mapActions } from 'vuex';
    import constants from "../constants";
    export default {
        name: "CardMethod",
        methods: {
            ...mapActions({
                setFeesType: constants.SET_FEES_TYPE
            }),
            card() {
                this.setFeesType(constants.DEFAULT_FEES_TYPE).then(() => {
                    this.$router.push({name: 'card'});
                });
            }
        }
    }
</script>

<style scoped>

</style>