import axios from 'axios';

export default {
    auth() {
        return axios.get('user');
    },
    countries() {
        return axios.get('countries');
    },
    momo(payment) {
        return axios.post('mobile-money', {payment: payment});
    },
    card(payment) {
        return axios.post('card', {payment: payment});
    },
    fees(params) {
        return axios.get('fees', {params: params});
    },
    showTransaction(payment) {
        return axios.get(`${payment}/show`);
    },
    defaultDriver(type) {
        return axios.get('default-driver', {params: {type: type}});
    }
};