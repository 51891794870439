import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

window.$ = window.jQuery = require('jquery');
import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/css/materialdesignicons.css'
import './assets/css/app.css'
import 'flag-icons/css/flag-icons.min.css'

// axios
import axios from 'axios'

// For Token
require('./store/modules/subscriber');

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = false;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

import dLocal from './plugin/dlocal';
import PayPal from './plugin/paypal';
import Cellulant from './plugin/cellulant';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(dLocal, {
        api_key: process.env.VUE_APP_DLOCAL_API_KEY,
        smart_fields_url: process.env.VUE_APP_DLOCAL_SMART_FIELDS_URL
    })
    .use(PayPal, {
        client_id: process.env.VUE_APP_PAYPAL_CLIENT_ID
    })
    .use(Cellulant, {
        access_key: process.env.VUE_APP_CELLULANT_ACCESS_KEY,
        environment: process.env.VUE_APP_CELLULANT_ENVIRONMENT,
        checkout_type: process.env.VUE_APP_CELLULANT_CHECKOUT_TYPE
    });

router.isReady().then(() => {
    app.mount('#app');
});
