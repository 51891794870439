import constants from "../../constants";
import api from '../../api';

export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    mutations: {
        SET_TOKEN (state, token) {
            state.token = token;
        },
        SET_USER (state, data) {
            state.user = data;
        }
    },
    getters: {
        AUTHENTICATED(state) {
            return state.token !== null && state.user !== null;
        },
        GET_USER(state) {
            return state.user;
        }
    },
    actions: {
        SET_TOKEN(context, token) {
            context.commit(constants.SET_TOKEN, token);
        },
        SET_USER(context, user) {
            context.commit(constants.SET_USER, user);
        },
        async ATTEMPT({ dispatch, state }, token) {
            if(token) {
                dispatch(constants.SET_TOKEN, token);
            }

            if(!state.token) {
                return;
            }

            try {
                let response = await api.auth();
                dispatch(constants.SET_USER, response.data);
            } catch (e) {
                dispatch(constants.SET_TOKEN, null);
                throw e;
            }
        }
    }
};
