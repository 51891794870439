import {createStore} from 'vuex'
import constants from "../constants";
import modules from "./modules";
import helpers from "../helpers";
import lo from 'lodash';
import api from "../api";

export default createStore({
    state: {
        origin: null,
        payment: null,
        transaction: null,
        fees: null,
        countries: [],
        country: null,
        card_driver: null,
        momo_driver: null,
        fees_type: 'default'
    },
    getters: {
        GET_PAYMENT(state, getters) {
            if (state.payment !== null && state.fees !== null) {

                return {
                    amount: lo.sum([lo.toNumber(state.payment.amount), lo.toNumber(getters.GET_FEES)]),
                    meta: {
                        fees: getters.GET_APPLIED_FEES,
                        ...state.payment.meta
                    },
                    ...lo.pick(state.payment, ['currency', 'email', 'fullname', 'phone_number'])
                };
            }

            return null;
        },
        GET_BASE_PAYMENT(state) {
            return state.payment
        },
        GET_TRANSACTION(state) {
            return state.transaction
        },
        GET_APPLIED_FEES(state) {
            return state.fees && lo.has(state.fees, state.fees_type) && state.fees[state.fees_type] ? state.fees[state.fees_type]: null;
        },
        GET_FEES(state, getters) {
            return getters.GET_APPLIED_FEES && getters.GET_APPLIED_FEES.fees ? getters.GET_APPLIED_FEES.fees: 0;
        },
        GET_DRIVER(state) {
            if (state.transaction && state.transaction.driver) {
                return state.transaction.driver;
            }

            return null;
        },
    },
    mutations: {
        SET_ORIGIN(state, origin) {
            state.origin = origin
        },
        SET_PAYMENT(state, payment) {
            state.payment = payment
        },
        SET_TRANSACTION(state, transaction) {
            state.transaction = transaction
        },
        SET_FEES(state, fees) {
            state.fees = fees
        },
        SET_FEES_TYPE(state, type) {
            state.fees_type = type
        },
        SET_COUNTRIES(state, countries) {
            state.countries = countries
        },
        SET_DEFAULT_CARD_DRIVER(state, driver) {
            state.card_driver = driver
        },
        SET_DEFAULT_MOMO_DRIVER(state, driver) {
            state.momo_driver = driver
        },
        UPDATE_COUNTRY(state, country) {
            state.country = country;
        }
    },
    actions: {
        SET_ORIGIN(context, origin) {
            context.commit(constants.SET_ORIGIN, origin);
        },
        SET_PAYMENT(context, payment) {
            let _payment = helpers.decrypT(payment);
            context.commit(constants.SET_PAYMENT, helpers.decrypt(_payment));
        },
        SET_TRANSACTION(context, transaction) {
            context.commit(constants.SET_TRANSACTION, transaction);
        },
        SET_FEES_TYPE(context, type) {
            context.commit(constants.SET_FEES_TYPE, type);
        },
        async SET_DEFAULT_MOMO_DRIVER({commit}) {
            let response = await api.defaultDriver('momo');
            commit(constants.SET_DEFAULT_MOMO_DRIVER, response.data.data);
        },
        async SET_DEFAULT_CARD_DRIVER({commit}) {
            let response = await api.defaultDriver('card');
            commit(constants.SET_DEFAULT_CARD_DRIVER, response.data.data);
        },
        async GET_COUNTRIES({commit}) {
            let response = await api.countries();
            commit(constants.SET_COUNTRIES, response.data.data);
        },
        async SET_FEES({state, commit}) {
            let action = '';
            if (lo.has(state.payment.meta, 'action')) {
                action = state.payment.meta.action;
            } else {
                if (lo.has(state.payment.meta, 'link')) {
                    action = 'link';
                }
            }

            let response = await api.fees({
                action: action,
                ...lo.pick(state.payment, ['currency', 'amount']),
                category: 'collections'
            });
            commit(constants.SET_FEES, response.data);
        },
        CLOSE({state}, message="Exit payment modal?") {
            if (state.origin) {
                if (confirm(message) === true) {
                    window.parent.postMessage({event: 'close'}, state.origin);
                }
            }
        }
    },
    modules: modules
})
