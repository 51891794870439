<template>
    <button type="submit" class="btn btn-primary btn-lg w-100 text-uppercase">
        Pay {{ payable }}
    </button>
</template>

<script>
    import {mapGetters} from 'vuex';
    import constants from "../constants";
    export default {
        name: "PayButton",
        computed: {
            ...mapGetters({
                payment: constants.GET_PAYMENT
            }),
            payable() {
                return `${this.payment.currency} ${this.commas(this.payment.amount)}`
            }
        },
        methods: {
            commas(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
    }
</script>

<style scoped>

</style>